@import '~@styles/_variables';

.btn {
  font-family: $font-heading;
  border-radius: 0px;
}

.btn.primary {
  background-color: $color-primary;
  border-color: $color-primary;
  color: $color-black;

  &:active {
    background-color: $color-primary-dark !important;
    border-color:  $color-primary-dark !important;
  }

  .icon {
    color: white;
  }
}

.btn.secondary {
  background-color: $color-black;
  border-color: $color-black;
  color: $color-white;

  .icon {
    color: $color-primary;
  }
}