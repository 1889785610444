@import '~@styles/_variables';

.responsive-tabs {
  display: flex;
  flex-direction: row;

  .nav-pane {
    padding: 0px;
    background-color: $color-grey-dark;
    overflow: hidden;
    font-family: $font-heading;

    .nav-link {
      background-color: #222;
      color: white;
      border-radius: 0px;

      &:hover {
        background-color: #1a1a1a;
      }

      &.active {
        background-color: $color-primary;
        color: white;
      }

      .icon {
        width: 20px;
        margin-right: 10px;
      }
    }

    @media (min-width: 401px) {
      flex-grow: 0;
      min-width: 200px;
      max-width: 200px;
    }

    @media (max-width: 400px) {
      flex-grow: 1;
    }
  }

  .content-pane {
    flex-grow: 1;
    background-color: white;
    overflow-y: auto;
    padding: 30px 15px;

    .back-btn {
      cursor: pointer;
    }

    @media (min-width: 401px) {      
      .back-btn {
        display: none;
      }
    }
  }

  &[data-active-pane="nav"] {
    @media (max-width: 400px) {
      .content-pane {
        display: none;
      }
    }
  }
  &[data-active-pane="content"] {
    @media (max-width: 400px) {
      .nav-pane {
        display: none;
      }
    }
  }
}