.map {
  display: flex;
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
}