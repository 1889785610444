@import '~@styles/_variables';
@import '~@styles/fonts';
@import '~@styles/btn';

html, body {
  font-family: $font-default;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-heading;
  font-weight: 700;
}
