.geo-data-type-panel {
  form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .form-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
  }

  .submit-btn {
    flex-grow: 0;
    height: 40px;
  }

  .dataset-section {
    margin-bottom: 15px;
  }

  .section-label {
    input[type='checkbox'] {
      margin-right: 5px;
    }

    h4 {
      display: inline-block;
    }
  }

  .label {
    .icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .disabled {
    opacity: 0.3;
  }

}