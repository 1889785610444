$color-primary: #76b82b;
$color-primary-dark: #00823e;
$color-black: #000000;
$color-grey-dark: #222222;
$color-white: #ffffff;

$font-heading: 'Kanit', 'Sukhumvit Set', 'Helvetica Neue', 'san-serif';
$font-default: 'Sarabun', 'Helvetica Neue', 'san-serif';


:export {
  colorPrimary: $color-primary;
  colorPrimaryDark: $color-primary-dark;
  colorBlack: $color-black;
  colorWhite: $color-white;
}