@import '~@styles/_variables';

.stats-panel {
  .stats-header {
    display: flex;
    justify-content: space-between;
    margin: -30px -15px 15px -15px;
    padding: 10px 15px;
    height: 40px;
    font-size: 16px;
    border-bottom: 1px solid #ddd;

    h2 {
      font-size: inherit;
    }

    .nav-right {
      .stats-view-mode-btn {
        cursor: pointer;
      }
    }
  }

  @media (max-width: 400px) {
    .nav-right {
      display: none;
    }
  }
}