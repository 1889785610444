@import '~@styles/_variables';

#homepage {
  .map {
    position: absolute;
    top: 88px; bottom: 50px;
    left: 0px; right: 0px;

    canvas {
      outline: none;
    }
    
    .popup-body {
      max-width: 300px;
      
      h4 {
        font-size: 16px;
      }

    }
  }

  .geo-data-button {
    position: absolute;
    bottom: 0px; left: 0px;
    width: 50vw; height: 50px;
    padding: 10px;
    border: 0px;
    border-radius: 0px;
    background-color: $color-black;
    color: $color-white;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.3);

    .icon {
      margin-right: 5px;
      color: $color-primary;
    }
  }

  .stats-data-button {
    position: absolute;
    bottom: 0px; right: 0px;
    width: 50vw; height: 50px;
    padding: 10px;
    border-radius: 0px;
    border: 0px;
    background-color: $color-primary;
    color: $color-black;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.3);

    .icon {
      margin-right: 5px;
      color: $color-white;
    }
  }

  .geo-data-type-panel {
    position: fixed;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    overflow: auto;

    @media (max-width: 400px) {
      top: 200px; bottom: 80px;
      left: 15px; right: 15px;
      
        .form-body {
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 30px;
      }

      .btn[type=submit] {
        position: absolute;
        bottom: 0; left: 0; right: 0; height: auto;
      }
    }

    @media (min-width: 401px) {
      bottom: 65px; left: 15px;
      right: 15px; top: 98px;
    }
  }

  .stats-panel {
    position: absolute;
    display: flex;
    flex-direction: column;

    .responsive-tabs {
      flex-grow: 1;
      height: 100%;
    }

    @media (max-width: 400px) {
      bottom: 50px; right: 0px;
      left: 0px; top: 88px;  
    }

    @media (min-width: 401px) {
      bottom: 50px; right: 0px;
      top: 88px; left: 0px;

    }
  }

  &.split {
    .stats-panel {
      @media (min-width: 401px) {
        left: 50%;
      }
    }
  }
}