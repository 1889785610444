/* Kanit */
@font-face {
  font-family: 'Kanit';
  src: url('../assets/fonts/Kanit/Kanit-Regular.ttf');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 200;
  src: url('../assets/fonts/Kanit/Kanit-Light.ttf');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 500;
  src: url('../assets/fonts/Kanit/Kanit-Medium.ttf');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 600;
  src: url('../assets/fonts/Kanit/Kanit-Medium.ttf');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 700;
  src: url('../assets/fonts/Kanit/Kanit-Bold.ttf');
}

/* Sarabun */
@font-face{
  font-family: 'Sarabun';
  src: url('../assets/fonts/Sarabun/Sarabun-Regular.ttf');
}

@font-face {
  font-family: 'Sarabun';
  font-weight: 200;
  src: url('../assets/fonts/Sarabun/Sarabun-Light.ttf');
}

@font-face {
  font-family: 'Sarabun';
  font-weight: 500;
  src: url('../assets/fonts/Sarabun/Sarabun-Medium.ttf');
}

@font-face {
  font-family: 'Sarabun';
  font-weight: 600;
  src: url('../assets/fonts/Sarabun/Sarabun-Medium.ttf');
}

@font-face {
  font-family: 'Sarabun';
  font-weight: 700;
  src: url('../assets/fonts/Sarabun/Sarabun-Bold.ttf');
}


