@import '~@styles/_variables';

#topnav {
  width: 100vw;
  background-color: $color-black;
  display: flex;
  justify-content: center;
  padding: 5px;

  .logo {
    height: 40px;
  }
}